<template>
    <Section>
      <Container>
        <div class="grid margin-bottom">
          <div class="tmp-col">
            <KeyValue label="Thème" :value="essai?.protocole?.thematique?.designation" />
            <KeyValue label="Titre protocole" :value="essai?.protocole?.titre"  />
            <KeyValue label="Code" :value="essai?.numero" />
            <KeyValue label="Culture">
              <template
                v-if="essai.protocole.id && essai.protocole.cultures.length > 0"
              >
                <div class="tags">
                  <MiniTag
                    v-for="culture in essai.protocole.cultures"
                    :key="culture.id"
                    :id="culture.uid"
                    :text="culture.nom"
                  />
                </div>
              </template>
              <template v-else>
                <span></span>
              </template>
            </KeyValue>
            <KeyValue label="Technicien" :value="essai?.technicien?.nom_complet" />
            <KeyValue label="Lieu" :value="essai?.parcelle?.designation" />
          </div>
          <div class="tmp-col">
            <KeyValue
              label="Nom de l'agricutlteur"
              :value="essai?.parcelle?.nom_agriculteur"
            />
            <KeyValue label="Email" :value="essai?.parcelle?.email" />
            <KeyValue label="Commune" :value="essai?.parcelle?.ville" />
            <KeyValue label="Téléphone" :value="essai?.parcelle?.tel" />
            <KeyValue label="Département" :value="essai?.parcelle?.cp" />
            <KeyValue
              label="Coordonnées GPS"
              :value="
              helperService.degToDms(essai?.parcelle?.latitude) +
              'N - ' +
              helperService.degToDms(essai?.parcelle?.longitude) +
              'E'
            "
            />
          </div>
        </div>

        <div class="grid margin-bottom">
          <div class="tmp-col col--span-3">
            <KeyValue label="Objectif" :value="essai?.protocole?.objectifs" />
          </div>
        </div>

        <div class="grid">
          <div class="tmp-col">
            <KeyValue
              label="Surface d'implantation"
              :value="surface_implantation"
            />
          </div>
          <div class="tmp-col">
            <KeyValue label="Surface d'application" :value="surface_application" />
          </div>
          <div class="tmp-col">
            <KeyValue label="Surface recoltée" :value="surface_recoltee" />
          </div>
        </div>

        <div v-if="essai?.parcelle?.latitude && essai?.parcelle?.longitude"
        class="map margin-top" id="map"></div>

        <div id="imageToken" class="map margin-top" v-if="essai?.parcelle?.media?.id">
          <ImageToken :defaultImage="true" :image="essai?.parcelle?.media" size="full" />
        </div>

      </Container>
    </Section>
  </template>

<script>
import Container from '@/components/layout/Container.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import L from 'leaflet'
import Section from '@/components/layout/Section.vue'
import ImageToken from '@/components/base/ImageToken.vue'

export default {
  name: 'GeneralInformationSubview',
  components: {
    ImageToken,
    Section,
    Container,
    KeyValue,
    MiniTag,
  },

  props: {
    pageTitle: {
      type: String,
    },
    essai: {
      type: Object,
    },
  },

  data() {
    return {
      myMap: null,
      center: [],
      positions: [],
      surface_recoltee: null,
      surface_implantation: null,
      surface_application: null,
    }
  },

  emits: ['updateEssais'],
  mounted() {
    if (
        this.essai?.parcelle?.latitude
        && this.essai?.parcelle?.longitude
    ) {
      this.center = [
        this.essai.parcelle.latitude,
        this.essai.parcelle.longitude,
      ]
      this.positions.push({
        lat: this.essai.parcelle.latitude,
        lon: this.essai.parcelle.longitude,
      })
      this.setupLeafletMap()
    }

    if (
      this.essai.surface_implantation_longueur
        && this.essai.surface_implantation_largeur
    ) {
      this.surface_implantation = `${
        this.essai.surface_implantation_longueur
          * this.essai.surface_implantation_largeur
      }m²`
    }
    if (
      this.essai.surface_application_longueur
        && this.essai.surface_application_largeur
    ) {
      this.surface_application = `${
        this.essai.surface_application_longueur
          * this.essai.surface_application_largeur
      }m²`
    }
    if (
      this.essai.surface_implantation_longueur
        && this.essai.surface_implantation_largeur
    ) {
      this.surface_recoltee = `${
        this.essai.surface_recoltee_longueur
          * this.essai.surface_recoltee_largeur
      }m²`
    }
  },
  methods: {
    setupLeafletMap() {
      const LeafIcon = L.Icon.extend({})
      const blueIcon = new LeafIcon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        shadowUrl:
            'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
      })

      this.myMap = L.map('map').setView(this.center, 13)
      L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
        attribution:
            `données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL -
            rendu <a href="//openstreetmap.fr">OSM France</a>`,
        minZoom: 1,
        maxZoom: 20,
      }).addTo(this.myMap)

      this.myMap.zoomControl.setPosition('bottomleft')

      this.positions.forEach((position) => {
        const marker = L.marker([position.lat, position.lon], {
          icon: blueIcon,
          title: this.essai.parcelle.designation,
          alt: this.essai.parcelle.designation,
        }).addTo(this.myMap)

        marker.bindPopup(position)
      })
    },
  },
}
</script>

  <style lang="scss" scoped>

  #imageToken{
    width: 80%;
  }
  #map {
    margin-top: $gutter;
    height: 500px;
    width: 80%;
  }
  </style>
